<template>
  <form-input
    class="saffron-country-input"
    v-bind="countryListInputAttrs"
    v-model="value"
    :label="false"
    type="select2"
    :error-message="''"
    :list="internalCountryList"
    :wrapper-margins="false"
    :auto-input-styleing="false">
  </form-input>
</template>

<script>
import CountryListInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/countryListInput.js";
import SimpleInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/simpleInput.js";

export default {
  mixins: [SimpleInputMixin, CountryListInputMixin],

  props: {
    translateList: {
      type: Boolean,
    }
  },
  computed: {

  }
};
</script>

<style lang="scss">
// NOTICE! non prefixed style! watch it!
.saffron-country-input {
  .field__controls {
    border: none!important;
  }
}
</style>
