export default {
  // notice: please use with SimpleInputMixin!
  // mixins: [SimpleInputMixin],
  inheritAttrs: false,
  props: {
    /**
     * Optional list of the only countries to show. Array if ISO country code strings, capitlized
     */
    countryList: {
      type: [Array, Boolean],
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    countryListInputAttrs() {
      let result = { ...this.noClassAttrs };

      delete result.legend;
      delete result.validationMessage;
      return result;
    },
    internalCountryList() {
      //import countries
      let countries = utilities.requireAsset("core/data/country-list.json");

      let simplified = Object.entries(countries).map((item) => {
        item[1].isoCode = item[0];
        return item[1];
      });

      // limit country list
      if (this.countryList) {
        simplified = simplified.map((item) => this.countryList.includes(item.isoCode));
      }

      let result = simplified.map((item) => {
        return {
          ...item,
          label: "(" + item.isoCode + ") " + item.name,
          value: item.isoCode,
        };
      });

      return result;
    },
    noClassAttrs() {
      let base = { ...this.$attrs };
      delete base.class;
      return base;
    },
  },
  methods: {},
  created() {},
  updated() {},
};
